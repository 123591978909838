<template>
    <VRow>
        <WidgetWrapper
            v-for="({component, config}, index) in widgets"
            :key="index"
            :component="component"
            :config="config || {}"
        />
    </VRow>
</template>

<script>
import ActiveCampaigns from '@/components/widgets/ActiveCampaigns';
import WidgetWrapper from '@/components/dashboard/WidgetWrapper';

export default {
    name: 'Dashboard',
    components: { WidgetWrapper },
    data() {
        return {
            widgets: [
                {
                    component: ActiveCampaigns,
                    config: {
                        columns: 8,
                        refreshRate: 10
                    },
                },
            ]
        }
    },
}
</script>

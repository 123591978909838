<template>
    <VApp
        id="microfrontend-application-container"
        class="dashboard"
    >
        <SrAppContainer
            class="dashboard-container"
            hide-sidebar
        >
            <template slot="content">
                <Dashboard />
            </template>
        </SrAppContainer>
    </VApp>
</template>
<script>
import { SrAppContainer } from '@ads/design-system';
import Dashboard from '@/components/dashboard/Dashboard';

export default {
    name: 'App',
    components: {
        Dashboard,
        SrAppContainer,
    },
};
</script>

<template>
    <SrTable
        class="active-campaigns"
        :items="items"
        :headers="headers"
        :loading="isLoading"
        loading-text="Loading active Campaigns"
        :striped="false"
        :show-footer="true"
    >
        <template
            v-slot:header.cost="header"
        >
            <SrHeadline
                v-show="totalCosts"
                level="2"
                color="brand-red--text"
            >
                {{ totalCosts | formatNumber }}
            </SrHeadline>
            <span>
                {{ header.text }}
            </span>
        </template>

        <template
            v-slot:header.impressions="header"
        >
            <SrHeadline
                v-show="totalImpressions"
                level="2"
                color="brand-red--text"
            >
                {{ totalImpressions | formatNumber }}
            </SrHeadline>
            <span>
                {{ header.text }}
            </span>
        </template>

        <template
            v-slot:header.clickTroughRate="header"
        >
            <SrHeadline
                v-show="avgClickTroughRate"
                level="2"
                color="brand-red--text"
            >
                {{ avgClickTroughRate | formatPercentage }}
            </SrHeadline>
            <span>
                {{ header.text }}
            </span>
        </template>

        <template
            v-slot:header.amountOfFinishedRegistrations="header"
        >
            <SrHeadline
                v-show="totalAmountOfFinishedRegistrations"
                level="2"
                color="brand-red--text"
            >
                {{ totalAmountOfFinishedRegistrations | formatNumber }}
            </SrHeadline>
            <span>
                {{ header.text }}
            </span>
        </template>

        <template
            v-slot:header.effectiveCostPerAction="header"
        >
            <SrHeadline
                v-show="avgEffectiveCostPerAction"
                level="2"
                color="brand-red--text"
            >
                {{ avgEffectiveCostPerAction | formatNumber }}
            </SrHeadline>
            <span>
                {{ header.text }}
            </span>
        </template>
    </SrTable>
</template>

<script>
import {
    formatNumber,
    formatPercentage,
    SrBodyText,
    SrHeadline,
    SrTable
} from '@ads/design-system';
import AbstractWidget from '@/components/widgets/AbstractWidget';
import ItemFactory from '@/components/widgets/ActiveCampaigns/ItemFactory';

const itemFactory = new ItemFactory();

export default {
    name: 'ActiveCampaigns',
    components: { SrTable, SrHeadline },
    filters: { formatNumber, formatPercentage },
    extends: AbstractWidget,
    data() {
        return {
            defaultColumns: 6,
            defaultRefreshRate: 60,
            items: [],
            headers: [
                {
                    text: 'Active Campaigns',
                    value: 'name',
                    align: 'start',
                    class: 'table-header',
                    valueConfig: {
                        component: SrBodyText,
                        componentConfig: {
                            level: 2,
                            class: 'blue--text',
                        }
                    }
                },
                {
                    text: 'Actual Cost',
                    value: 'cost',
                    align: 'center',
                    class: 'table-header',
                    valueConfig: {
                        formatter: formatNumber
                    }
                },
                {
                    text: 'Impressions',
                    value: 'impressions',
                    align: 'center',
                    class: 'table-header',
                    valueConfig: {
                        formatter: formatNumber
                    }
                },
                {
                    text: 'CTR',
                    value: 'clickTroughRate',
                    align: 'center',
                    class: 'table-header',
                    valueConfig: {
                        formatter: formatPercentage
                    }
                },
                {
                    text: 'Reg. Finished',
                    value: 'amountOfFinishedRegistrations',
                    align: 'center',
                    class: 'table-header',
                    valueConfig: {
                        formatter: formatNumber
                    }
                },
                {
                    text: 'eCPA',
                    value: 'effectiveCostPerAction',
                    align: 'center',
                    class: 'table-header',
                    valueConfig: {
                        formatter: formatNumber
                    }
                },
            ]
        };
    },
    computed: {
        totalCosts () {
            return this.getSumOfProperty(this.items, 'cost');
        },
        totalImpressions () {
            return this.getSumOfProperty(this.items, 'impressions');
        },
        totalAmountOfFinishedRegistrations() {
            return this.getSumOfProperty(this.items, 'amountOfFinishedRegistrations');
        },
        avgClickTroughRate() {
            return this.getAvgOfProperty(this.items, 'clickTroughRate');
        },
        avgEffectiveCostPerAction() {
            return this.getAvgOfProperty(this.items, 'effectiveCostPerAction');
        },
    },
    methods: {
        getSumOfProperty(items, propertyKey) {
            return items.reduce((totalValue, item) => {
                return totalValue + item[propertyKey];
            }, 0);
        },

        getAvgOfProperty(items, propertyKey) {
            return this.getSumOfProperty(items, propertyKey) / items.length;
        },

        fetchData() {
            this.isLoading = true;
            const randomDelay = Math.floor(Math.random() * 500) + 1;

            setTimeout(() => {
                this.items = itemFactory.generateItems();
                this.isLoading = false;
            }, randomDelay);

        }
    }
}
</script>

<template>
    <VCol
        :cols="columns"
        class="widget-wrapper"
    >
        <component
            :is="component"
            v-bind="config"
        />
    </VCol>
</template>

<script>
export default {
    name: 'WidgetWrapper',
    props: {
        config: {
            type: Object,
            default: null
        },
        component: {
            type: Object,
            required: true
        }
    },
    computed: {
        columns() {
            return this.config.columns || this.component.defaultColumns
        }
    }
}
</script>

export default {
    abstract: true,
    props: {
        refreshRate: {
            type: Number,
            required: false,
        }
    },
    data() {
        return {
            defaultColumns: 6,
            defaultRefreshRate: 0,
            refreshInterval: null,
            isLoading: true,
        }
    },
    mounted() {
        this.fetchData();
        this.startRefreshInterval();
    },
    computed: {
        computedRefreshRate() {
            return this.refreshRate || this.defaultRefreshRate;
        }
    },
    methods: {
        fetchData() {
            throw Error('Method fetchData needs to be implemented.')
        },
        startRefreshInterval() {
            if(this.computedRefreshRate === 0) {
                return false;
            }
            this.refreshInterval = setInterval(this.fetchData, this.computedRefreshRate * 1000);
        }
    }
}

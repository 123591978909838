import Vue from 'vue';
import App from './App';
import DesignSystem, {vuetify} from '@ads/design-system';
import '@/scss/main.scss';
import singleSpaVue from 'single-spa-vue';

Vue.use(DesignSystem);

const vueLifeCycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        render: h => h(App),
        el: '#microfrontend-application-container'
    }
});

export const bootstrap = vueLifeCycles.bootstrap;
export const mount = vueLifeCycles.mount;
export const unmount = vueLifeCycles.unmount;

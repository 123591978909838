const BRAND_NAMES = ['Bookmaker', 'Bet-at-home', 'Bwin', 'Betway', 'Laola1'];
const COUNTRIES = ['AT', 'DE', 'BR', 'ES', 'CH'];
const PERIOD = ['AUG 19', 'NOV 19', 'FEB 20', 'Q4 19', 'Q1 20'];

const IMPRESSIONS_MIN = 100000; // 100k
const IMPRESSIONS_MAX = 5000000; // 5m

const CTR_MIN = 0.0005; // 0.5%
const CTR_MAX = 0.02; // 2%

const AVG_REG_MIN = 1 / 10000; // 1 in 10k
const AVG_REG_MAX = 1 / 200; // 1 in 200

const AVG_CPM_MIN = 0.5; // 0.5€
const AVG_CPM_MAX = 3; // 3€

export default class ItemFactory {
    generateItems(amount = 5) {
        const items = [];

        for (let i = 0; amount > i; i++) {
            items.push(this.generateItem());
        }

        return items;
    }

    generateItem() {
        const name = this.generateRandomName();
        const impressions = this.getRandomImpressions();
        const avgRegistrationRate = this.getRandomAvgRegistrationRate();
        const cost = (impressions / 1000) * this.getRandomCPM();
        const amountOfFinishedRegistrations = Math.floor(impressions * avgRegistrationRate);
        const clickTroughRate = this.getRandomCTR();
        const effectiveCostPerAction = cost / amountOfFinishedRegistrations;

        return {
            name,
            cost,
            impressions,
            amountOfFinishedRegistrations,
            clickTroughRate,
            effectiveCostPerAction,
        }
    }

    getRandomValueFromArray(array) {
        return array[Math.floor(Math.random() * array.length)]
    }

    getRandomMinMaxValue(min, max) {
        return Math.random() * (max - min) + min;
    }

    generateRandomName() {
        const randomBrandName = this.getRandomValueFromArray(BRAND_NAMES);
        const randomCountry = this.getRandomValueFromArray(COUNTRIES);
        const randomPeriod = this.getRandomValueFromArray(PERIOD);

        return `${randomBrandName} ${randomCountry} ${randomPeriod}`
    }

    getRandomImpressions() {
        return Math.floor(this.getRandomMinMaxValue(IMPRESSIONS_MIN, IMPRESSIONS_MAX));
    }

    getRandomCTR() {
        return this.getRandomMinMaxValue(CTR_MIN, CTR_MAX);
    }

    getRandomCPM() {
        return this.getRandomMinMaxValue(AVG_CPM_MIN, AVG_CPM_MAX);
    }

    getRandomAvgRegistrationRate() {
        return this.getRandomMinMaxValue(AVG_REG_MIN, AVG_REG_MAX);
    }
}
